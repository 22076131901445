import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "gatsby";
import logoText from "../images/logo-text.png";
import { useState } from "react";
import {
  PhoneEnabled,
  PhoneEnabledTwoTone,
  PhoneInTalk,
} from "@mui/icons-material";

const NavBar = ({ data }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const root = {
    logo: {
      height: {
        xs: 30,
        sm: 50,
      },
      width: "auto",
      marginRight: 1,
      marginTop: 1,
    },
    phone: {
      color: "#333",
    },
    link: {
      color: "gray",
      display: "block",
      textTransform: "uppercase",
      "&:visited ": {
        color: "gray",
      },
      "&:active ": {
        color: "gray",
      },
    },
  };

  const pages = [
    { text: "start", href: "/" },
    { text: "produkter", href: "/produkter" },
    { text: "vårt arbete", href: "/vart-arbete" },
    { text: "kontor", href: "/kontor" },
    { text: "kontakt", href: "/kontakt" },
  ];

  return (
    <AppBar sx={{ bgcolor: "#fff" }} elevation={2} position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Link to="/">
              <Box
                component="img"
                sx={root.logo}
                alt="Folksol"
                src={logoText}
              />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              href="tel:+468206718"
              color="primary"
              aria-label="ring oss"
            >
              <PhoneInTalk />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Link to="/">
              <Box
                component="img"
                sx={root.logo}
                alt="Folksol"
                src={logoText}
              />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "flex", md: "none" },
              float: "right",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="Meny"
              edge="start"
              onClick={handleOpenNavMenu}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.text} onClick={handleCloseNavMenu}>
                  <Link to={page.href}>
                    <Typography textAlign="center" sx={root.link}>
                      {page.text}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button href={page.href} sx={root.link} key={page.text}>
                {page.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
